import usFlagIcon from 'src/assets/usFlag.svg'
import internationalIcon from 'src/assets/international.png'

import { SpecificMember } from './execute-template/specific-members'
import { EAirdropIssueType } from 'src/types/common.types'

export const formatValue = (key: string, value: string | boolean, template: any) => {
  switch (key) {
    case 'rewardType':
      return value === 'IsFixedAmount' ? 'Fixed amount' : '-'
    case 'name':
      return value || '-'

    default:
      if (value === 'OwnedTokens') return 'By owned shares/tokens'
      if (value === 'IntroducedTokens') return 'By introduced shares/tokens'

      return `${value}`.replace(/([A-Z]+)/g, ' $1') || '-'
  }
}
export const defaultValues = {
  name: '',
  region: '',
  category: '',
  percentage: '',
  amountSum: '',
  rewardType: '',
  frequency: '',
  distribution: '',
  isVotingRequired: '',
  budgetType: '',
  specificMembers: [{ name: '', walletAddress: '', amount: '' }] as SpecificMember[],
  budget: ''
}

export const stepFields = [
  'name',
  'region',
  'category',
  'rewardType,percentage,amountSum',
  'frequency',
  'distribution',
  'isVotingRequired',
  'budgetType,specificMembers'
].reduce(
  (acc: string[], next, index): string[] => [
    ...acc,
    `${acc[index - 1] ? `${acc[index - 1]},` : ''}${next}`
  ],
  []
)

export const regionOptions = [
  {
    value: 'International',
    label: (
      <div className="main">
        <img src={internationalIcon} alt="NonUS" style={{ marginRight: '10px', width: '16px' }} />
        International Members
      </div>
    )
  },
  {
    value: 'US',
    label: (
      <div className="main">
        <img src={usFlagIcon} alt="US" style={{ marginRight: '10px', width: '16px' }} />
        US Members
      </div>
    )
  }
]

export const categoryOptions = [
  {
    value: 'FoundingMembers',
    label: (
      <>
        <div className="main">Founding Members Only</div>
        <div className="second">Member must have at least 1 NFT Founding Member (SXT)</div>
      </>
    )
  },
  {
    value: 'AllMembers',
    label: (
      <>
        <div className="main">All Members</div>
        <div className="second">Member must have at least 1 NFT of any type</div>
      </>
    )
  },
  {
    value: 'SpecificMembers',
    label: (
      <>
        <div className="main">Specific Members</div>
        <div className="second">
          Members will be specified later manually on using this template disregard their NFT
          ownership
        </div>
      </>
    )
  }
]

export const frequencyOptions = [
  {
    value: 'Monthly',
    label: (
      <>
        <div className="main">
          Monthly <span>(Last month)</span>
        </div>
        <div className="second">All calculations will be done for the previous month</div>
      </>
    )
  },
  {
    value: 'Quarterly',
    label: (
      <>
        <div className="main">
          Quarterly <span>(Last quarter)</span>
        </div>
        <div className="second">All calculations will be done for the previous quarter</div>
      </>
    )
  },
  {
    value: 'Anually',
    label: (
      <>
        <div className="main">
          Annual <span>(Previous Year)</span>
        </div>
        <div className="second">All calculations will be done for the previous 12 months</div>
      </>
    )
  }
]

export const distributionOptions = [
  {
    value: 'OwnedTokens',
    label: (
      <>
        <div className="main">By owned shares/tokens</div>
        <div className="second">
          Investor will receive 1 point for each share/token owned in time
        </div>
      </>
    )
  },
  {
    value: 'IntroducedTokens',
    label: (
      <>
        <div className="main">By introduced shares/tokens</div>
        <div className="second">
          Investor will receive 1 point for each share/token introduced in time{' '}
        </div>
      </>
    )
  },

  {
    value: 'Manual',
    label: (
      <>
        <div className="main">Manual</div>
        <div className="second">Budget is distributed manually to each specified investor</div>
      </>
    )
  }
]

export const votingOptions = [
  {
    value: 'yes',
    label: (
      <>
        <div className="main">Yes, choose only members who voted in the chosen period </div>
      </>
    )
  },
  {
    value: 'no',
    label: (
      <>
        <div className="main">No, all members are eligible</div>
      </>
    )
  }
]

export const issuesFormOptions = [
  {
    title: 'Issue Airdrop (Dividends)',
    description: 'All fields to be filled manually',
    issueType: EAirdropIssueType.Dividends
  },
  {
    title: 'Issue Airdrop (Introductions)',
    description: 'All fields to be filled manually',
    issueType: EAirdropIssueType.Introductions
  }
]
